import React, { useState, Fragment } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Listbox, Transition } from "@headlessui/react";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanPrograms";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import PortableText from "../components/Blog/portableText";

export const query = graphql`
  query CityTemplateQuery($id: String!) {
    city: sanityCities(id: { eq: $id }) {
      id
      title
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      state
      whyWeLoveImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
      }
      _rawWhyWeLoveText
    }
  }
`;

const Template = ({ data }) => {
  const loanPrograms = [
    { name: "Reverse Mortgage", href: "/reverse-mortgage-orange-county/" },
    { name: "Refinance Loans", href: "/refinance-orange-county/" },
    { name: "Purchase Loans", href: "/purchase-orange-county/" },
    { name: "Non-QM Loans", href: "/non-qm-loans/" },
    { name: "Private Loans", href: "/hard-money-loans-orange-county/" },
    { name: "FHA & VA Loans", href: "/fha-va-loans-orange-county/" },
  ];

  const [selectedLoanProgram, setSelectedLoanProgram] = useState(
    loanPrograms[0]
  );
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.city.seoTitle}
        description={data.city.metaDescription}
        openGraphImage={
          data.city.openGraphImage && data.city.openGraphImage.asset.url
        }
        twitterOpenGraphImage={
          data.city.twitterCardImage && data.city.twitterCardImage.asset.url
        }
      />

      <section className="relative mb-12 overflow-hidden pt-10 md:mb-32 md:pt-36 md:pb-40">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-desktop.jpg"
            loading="eager"
            layout="fullWidth"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative mb-10 max-w-[520px] md:mb-0">
            <div className="mb-4 font-heading text-mobile-7xl font-extrabold text-typography-heading md:text-7xl md:text-white">
              We get loans that{" "}
              <span className="md:text-primary-500">
                other mortgage brokers can’t.
              </span>
            </div>
            <p className="mb-9 text-xl md:mb-7 md:text-[1.75rem] md:leading-[2.375rem] md:text-white">
              Because we’re more than home loan experts—we’re also attorneys.
            </p>

            <div className="mb-1 text-sm font-medium text-typography-heading/60 md:mb-2 md:text-base md:text-white/60">
              What can we help you with?
            </div>

            <div className="flex w-full">
              <Listbox
                value={selectedLoanProgram}
                onChange={setSelectedLoanProgram}
              >
                <div className="relative w-full">
                  <Listbox.Button className="relative flex h-16 w-full cursor-pointer items-center justify-between space-x-4 rounded-tl rounded-bl border border-gray-300 bg-white px-6 text-left font-normal text-gray-600 no-underline hover:text-gray-600 focus:outline-none md:border-none">
                    <span className="block truncate font-medium">
                      {selectedLoanProgram.name}
                    </span>
                    <i className="fa-regular fa-chevron-down text-3xl"></i>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute left-0 z-10 mt-2 max-h-36 w-[456px] overflow-auto bg-white pt-2 text-left text-sm shadow-lg focus:outline-none">
                      {loanPrograms.map((loanProgram, i) => (
                        <Listbox.Option
                          key={i}
                          value={loanProgram}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-primary-500 text-white"
                                : "text-gray-600"
                            }
                          relative cursor-pointer select-none py-2 px-6`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`${
                                  selected && "font-medium"
                                } block truncate`}
                              >
                                {loanProgram.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-primary-500">
                                  <i className="fa-solid fa-check text-lg"></i>
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <div className="h-16 min-w-[4rem] overflow-hidden rounded-tr rounded-br">
                <Link
                  href={selectedLoanProgram.href}
                  className="flex h-full items-center justify-center bg-primary-500 text-white no-underline hover:bg-primary-900 hover:text-white"
                >
                  <i className="fa-regular fa-arrow-right text-xl"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-mobile.jpg"
            loading="eager"
            layout="fullWidth"
          />
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <h1>A Top {data.city.title} Mortgage Broker</h1>
              <p className="mb-0">
                Sometimes finding the right loan can take some work. At Nikkael
                Home Loans, we’re attorney-owned, and our team specializes in
                complex mortgages. That way, you can focus on finding the home
                you want, not worrying about whether you qualify for a loan.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <LoanPrograms />
      {/* <HomeWorth location={data.city.title} /> */}
      <ValueProps />
      <About location={data.city.title} />
      <Testimonials />

      {data.city._rawWhyWeLoveText && data.city.whyWeLoveImage && (
        <section className="mb-20 md:mb-32">
          <div className="container">
            <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <div className="order-2 md:order-1">
                <h2>
                  Making {data.city.title}, {data.city.state} Your Home
                </h2>
                <PortableText blocks={data.city._rawWhyWeLoveText} />
              </div>
              <div className="order-1 md:order-2">
                <GatsbyImage
                  image={data.city.whyWeLoveImage.asset.gatsbyImageData}
                />
              </div>
            </div>
          </div>
        </section>
      )}

      <CallToAction />
    </Layout>
  );
};

export default Template;
